import * as React from 'react';
import { graphql } from 'gatsby';

import { NotFoundPageContent } from 'modules/NotFoundPageContent';
import { Template } from 'common/layout';
import { PageTransition } from 'common/layout/PageTransition';

const NotFoundPage: React.FC<NotFoundPageProps> = ({ data }) => {
  const textContent = data?.contentfulWebsiteNotFoundText?.text;

  return (
    <Template>
      <PageTransition>
        <NotFoundPageContent content={textContent || ''} />
      </PageTransition>
    </Template>
  );
};

export const query = graphql`
  query NotFoundPage($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          language
          data
        }
      }
    }
    contentfulWebsiteNotFoundText(node_locale: {eq: $language}) {
      id
      text
    }
  }
`;

type NotFoundPageProps = {
  data: GatsbyTypes.NotFoundPageQuery;
}

export default NotFoundPage;
