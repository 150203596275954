import * as React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { Container } from 'common/layout';
import { Button } from 'common/interaction';

import { ContentContainer, Logo, ExtendedHeading } from './styled';

export const NotFoundPageContent: React.FC<NotFoundContentProps> = ({ content }) => {
  const { t } = useTranslation();

  return (
    <Container $center $fullHeight $backgroundColor>
      <ContentContainer>
        <Logo />
        <ExtendedHeading upperCase isCentered as="h2">{content}</ExtendedHeading>
        <Button to="/">{t('go back home')}</Button>
      </ContentContainer>
    </Container>
  );
};

type NotFoundContentProps = {
  content: string;
};
