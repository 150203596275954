import styled from 'styled-components';

import { media } from 'styles/utils';
import LogoSvg from 'vectors/logo-svg.svg';
import { Heading } from 'common/typography';

export const ExtendedHeading = styled(Heading)`
  font-size: 20px;
  line-height: 35px;
  font-weight: 300;
  letter-spacing: 3.3px;

  ${media.tablet`
    font-size: 35px;
    line-height: 44px;
  `}  
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 480px;
  max-width: 520px;
`;

export const Logo = styled(LogoSvg)`
  width: 220px;

  ${media.tablet`
    width: 349px;
  `}
`;
